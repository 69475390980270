import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/Seo";
import Layout from "../components/Layout";
import FlexContentBlocks from "../query/FlexContentBlocks";

const AboutUsPage = ({ data }: { data: any }) => {
  const WP = data.wp.pages.edges[0].node;
  const WP_SEO = WP.seo;
  const WP_ACF = WP.aboutusAcf.content;

  return (
    <Layout
      headerLocation="about"
    >
      <SEO
        title={WP_SEO.title}
        description={WP_SEO.metaDesc}
        url={'/about-us/'}
        image={WP_SEO.opengraphImage.sourceUrl}
      />
      <FlexContentBlocks contentBlocks={WP_ACF} pageData={data} />
    </Layout>
  );
};

export default AboutUsPage;

export const pageQuery = graphql`
  query {
    wp {
      pages(where: { name: "about-us" }) {
        edges {
          node {
            seo {
              title
              canonical
              focuskw
              metaDesc
              metaKeywords
              metaRobotsNofollow
              metaRobotsNoindex
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              twitterDescription
              twitterTitle
              opengraphPublisher
              opengraphSiteName
              opengraphUrl
              opengraphType
              opengraphTitle
              breadcrumbs {
                text
                url
              }
              opengraphImage {
                altText
                caption
                commentCount
                commentStatus
                databaseId
                date
                dateGmt
                description
                desiredSlug
                guid
                id
                isRestricted
                link
                mediaItemId
                mediaItemUrl
                mediaType
                mimeType
                modified
                modifiedGmt
                sizes
                slug
                sourceUrl
                srcSet
                status
                title
                seo {
                  breadcrumbs {
                    text
                    url
                  }
                  canonical
                  focuskw
                  metaDesc
                  metaKeywords
                  metaRobotsNofollow
                  metaRobotsNoindex
                  opengraphAuthor
                  opengraphDescription
                  opengraphModifiedTime
                  opengraphPublishedTime
                  opengraphPublisher
                  opengraphSiteName
                  opengraphTitle
                  opengraphType
                  opengraphUrl
                  title
                  twitterDescription
                  twitterTitle
                }
                enclosure
              }
              twitterImage {
                altText
                caption
                commentCount
                commentStatus
                databaseId
                date
                dateGmt
                description
                desiredSlug
                enclosure
                guid
                id
                isRestricted
                link
                mediaItemId
                mediaItemUrl
                title
                status
                srcSet
                sourceUrl
                slug
                sizes
                modifiedGmt
                modified
                mimeType
                mediaType
              }
            }
            aboutusAcf {
              content {
                ... on wp_Page_Aboutusacf_Content_HeroWithImage {
                  fieldGroupName
                  imageMobile {
                    altText
                    mediaItemUrl
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: NONE
                          width: 768
                          quality: 50
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                  imageDesktop {
                    altText
                    mediaItemUrl
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: NONE
                          width: 1400
                          quality: 100
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                }
                ... on wp_Page_Aboutusacf_Content_TwoColumnsText {
                  heading
                  fieldGroupName
                  rightSide
                  leftSide
                }
                ... on wp_Page_Aboutusacf_Content_Divider {
                  fieldGroupName
                  sectionId
                }
                ... on wp_Page_Aboutusacf_Content_FullWidthText {
                  fieldGroupName
                  text
                }
                ... on wp_Page_Aboutusacf_Content_Banner {
                  fieldGroupName
                  backgroundImageMobile {
                    altText
                    mediaItemUrl
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: NONE
                          width: 800
                          quality: 50
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                  backgroundImageDesktop {
                    altText
                    mediaItemUrl
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: NONE
                          width: 1400
                          quality: 70
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                  sectionId
                  heading
                  subheading
                }
                ... on wp_Page_Aboutusacf_Content_Timeline {
                  fieldGroupName
                  heading
                  description
                  iconsWithDescription {
                    fieldGroupName
                    icon {
                      altText
                      sizes
                      srcSet
                      sourceUrl
                      mediaDetails {
                        width
                        height
                      }
                    }
                    description
                  }
                  fullWidthText
                }
                ... on wp_Page_Aboutusacf_Content_Cta {
                  fieldGroupName
                  sectionId
                  heading
                  subheading
                  image {
                    altText
                    mediaItemUrl
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: NONE
                          width: 800
                          quality: 70
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                  darkButton {
                    target
                    title
                    url
                  }
                  lightButton {
                    target
                    title
                    url
                  }
                }
                ... on wp_Page_Aboutusacf_Content_Bios {
                  fieldGroupName
                  bios {
                    fieldGroupName
                    image {
                      altText
                      mediaItemUrl
                      imageFile {
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED
                            placeholder: NONE
                            width: 800
                            quality: 70
                            formats: [AUTO, WEBP]
                          )
                        }
                      }
                    }
                    fullName
                    description
                    text
                  }
                }
                ... on wp_Page_Aboutusacf_Content_Text {
                  fieldGroupName
                  sectionId
                  reverseElements
                  heading
                  text
                  button {
                    target
                    title
                    url
                  }
                }
              }
              fieldGroupName
            }
          }
        }
      }
    }
  }
`;

import React from "react";

import FlexHeroHomepage from "./blocks/FlexHeroHomepage";
import FlexCtaSection from "./blocks/FlexCtaSection";
import FlexTextColumn from "./blocks/FlexTextColumn";
import FlexTextColumnAlt from "./blocks/FlexTextColumnAlt";
import FlexDivider from "./blocks/FlexDivider";
import FlexDividerLarge from "./blocks/FlexDividerLarge";
import FlexUvp from "./blocks/FlexUvp";
import FlexContentBanner from "./blocks/FlexContentBanner";
import FlexContentBannerAlt from "./blocks/FlexContentBannerAlt";
import FlexClients from "./blocks/FlexClients";
import FlexFeatures from "./blocks/FlexFeatures";
import FlexHeroNav from "./blocks/FlexHeroNav";
import FlexHireFeatures from "./blocks/FlexHireFeatures";
import FlexGetHiredFeatures from "./blocks/FlexGetHiredFeatures";
import FlexHireCta from "./blocks/FlexHireCta";
import FlexGetHiredCta from "./blocks/FlexGetHiredCta";
import FlexHireBgBlock from "./blocks/FlexHireBgBlock";
import FlexGetHiredBgBlock from "./blocks/FlexGetHiredBgBlock";
import FlexHeroBanner from "./blocks/FlexHeroBanner";
import FlexTwoColumnsText from "./blocks/FlexTwoColumnsText";
import FlexFullWidthText from "./blocks/FlexFullWidthText";
import FlexBios from "./blocks/FlexBios";
import FlexTimeline from "./blocks/FlexTimeline";

const FlexContentBlocks = ({ contentBlocks, pageData: { wp } }) => {
  let type: string;

  if (wp.hasOwnProperty("pages")) {
    type = "page";
  }

  return contentBlocks.map((block: any, index: number) => {
    const { fieldGroupName } = block;

    switch (fieldGroupName) {
      case `${type}_Homepageacf_Content_Cta`:
        return <FlexCtaSection key={index} block={block} index={index} />;

      case `${type}_Homepageacf_Content_Text`:
        return <FlexTextColumn key={index} block={block} index={index} />;

      case `${type}_Homepageacf_Content_Divider`:
        return <FlexDivider key={index} block={block} index={index} />;

      case `${type}_Homepageacf_Content_UniqueValueProposition`:
        return <FlexUvp key={index} block={block} index={index} />;

      case `${type}_Homepageacf_Content_Banner`:
        return <FlexContentBanner key={index} block={block} index={index} />;

      case `${type}_Homepageacf_Content_Clients`:
        return <FlexClients key={index} block={block} />;

      case `${type}_Homepageacf_Content_Features`:
        return <FlexFeatures key={index} block={block} index={index} />;

      case `${type}_Homepageacf_Content_HeroWithAnimation`:
        return <FlexHeroHomepage key={index} block={block} index={index} />;

      case `${type}_Getstartedacf_Content_HireGetHired`:
        return <FlexHeroNav key={index} block={block} index={index} />;

      case `${type}_Getstartedacf_Content_Hire`:
        return <FlexHireFeatures key={index} block={block} />;

      case `${type}_Getstartedacf_Content_GetStartedCta`:
        return <FlexHireCta key={index} block={block} index={index} />;

      case `${type}_Getstartedacf_Content_TestimonialsWithForm`:
        return <FlexHireBgBlock key={index} block={block} index={index} />;

      case `${type}_Getstartedacf_Content_Banner`:
        return <FlexContentBanner key={index} block={block} index={index} />;

      case `${type}_Getstartedacf_Content_GetHired`:
        return <FlexGetHiredFeatures key={index} block={block} />;

      case `${type}_Getstartedacf_Content_GetHiredCta`:
        return <FlexGetHiredCta key={index} block={block} />;

      case `${type}_Getstartedacf_Content_Testimonials`:
        return <FlexGetHiredBgBlock key={index} block={block} />;

      case `${type}_Aboutusacf_Content_HeroWithImage`:
        return <FlexHeroBanner key={index} block={block} index={index} />;

      case `${type}_Aboutusacf_Content_TwoColumnsText`:
        return <FlexTwoColumnsText 
          heading="h1"
          key={index} 
          block={block} 
          cssColumns={true}
        />;

      case `${type}_Aboutusacf_Content_FullWidthText`:
        return <FlexFullWidthText key={index} block={block} index={index} />;

      case `${type}_Aboutusacf_Content_Divider`:
        return <FlexDividerLarge key={index} block={block} index={index} />;

      case `${type}_Aboutusacf_Content_Bios`:
        return <FlexBios key={index} block={block} index={index} />;

      case `${type}_Aboutusacf_Content_Timeline`:
        return <FlexTimeline key={index} block={block} index={index} />;

      case `${type}_Aboutusacf_Content_Banner`:
        return <FlexContentBannerAlt key={index} block={block} />;

      case `${type}_Aboutusacf_Content_Text`:
        return <FlexTextColumnAlt key={index} block={block} index={index} />;

      case `${type}_Aboutusacf_Content_Cta`:
        return <FlexCtaSection key={index} block={block} index={index} />;
      default:
        break;
    }
    return false;
  });
};
export default FlexContentBlocks;
